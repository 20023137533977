import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB0vtUVgUNkaG1y9Nup3yv-LPHZYN-GvDc",
  authDomain: "vue-firebase-f1289.firebaseapp.com",
  projectId: "vue-firebase-f1289",
  storageBucket: "vue-firebase-f1289.appspot.com",
  messagingSenderId: "782750853326",
  appId: "1:782750853326:web:beea705e81f2972fa0089f",
};

firebase.initializeApp(firebaseConfig);

const projectAuth = firebase.auth();
const projectFirestore = firebase.firestore();
const timeStamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectFirestore, timeStamp, projectAuth };
